import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { DealsQueueData, ThinkerQueueData } from '../../models/deals/deals-queue';

export interface ThinkerQueueProps {
    type: string;
    status: DealsQueueData;
    deals: ThinkerQueueData[];
}

const ThinkerQueue: FC<ThinkerQueueProps> = ({ type, status, deals }) => {
    return (
        <>
            <div>{type.split(' ').map(word => word[0].toUpperCase() + word.substring(1)).join(' ')}: </div>
            <div><i><FontAwesomeIcon icon="arrow-left" /></i></div>
            <div className="queue-deals" id={`thinker-${type}-queue`}>
                {deals && deals.length > 0 ? (
                    deals.map((deal, index) => (
                        <Link key={index}
                            className={`${status.is_manager_queue ? 'tooltip' : ''} ${deal.is_wasabi_pod ? 'wasabi-pod-deals' : ''} ${deal.is_in_training_pod ? 'in-training-pod-deals' : ''} ${type} spaced ${status.tracker_user_id.toString() === deal.user_id ? 'yours' : ''} ${deal.requested_thinker_pod ? 'requested' : ''} ${(status.is_manager_queue || status.see_concierge_deals) && deal.is_concierge_loan ? 'concierge' : ''}`}
                            to={status.show_deal_id || deal.user_id === status.tracker_user_id.toString() ? `/deals/details?deal_id=${deal.deal_id}` : ''}>
                            {type === 'premier' ? (<><FontAwesomeIcon icon={faStar} />{' '}</>) : null}
                            {status.show_deal_id || deal.user_id === status.tracker_user_id.toString() ? deal.deal_id : <>&nbsp;</>}
                            {deal.admin_data ? (
                                <span className="tooltip-table">Claim Pay Data
                                    <table className="faux-table table-builder">
                                        <tbody>
                                            {Object.entries(deal.admin_data).map(([key, value]) => (
                                                <tr className="faux-row" key={key}>
                                                    <td className="faux-cell">{key}</td>
                                                    <td className="faux-cell">{value}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </span>
                            ) : null}
                        </Link>
                    ))
                ) : (
                    <span className="empty">Queue Empty</span>
                )}
            </div>
        </>

    );
};

export default ThinkerQueue;
