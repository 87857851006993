import { Observable } from 'rxjs';
import { TableSchema } from '../models/schemas/table-schema';
import { HttpService as HttpService } from './http.service';
import { ActionButtons } from '../models/schemas/action-header';
import { VerbalerDraftConfig } from '../pages/verbalers/verbaler-draft.component';
import { CompanyData } from '../pages/verbalers/verbal-company-card.component';
import { SelectComponent } from '../models/schemas/form-schema';
import { Filters } from './report.service';

export class VerbalerService extends HttpService {

    getPriorityInbox(): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>('/verbaler/priority-inbox');
    }

    getVerbalerRequests(): Observable<[TableSchema, TableSchema]> {
        return this.fetchJsonObservable<[TableSchema, TableSchema]>('/verbaler/requests');
    }

    getCompletedRequests(searchParam: string|null = null): Observable<TableSchema> {
        const queryParams: string = (searchParam) ? `?search_param=${searchParam}` : '';
        return this.fetchJsonObservable<TableSchema>(`/verbaler/completed-requests${queryParams}`);
    }

    getVerbalerDrafts(deal_id: string | undefined): Observable<[TableSchema, TableSchema, TableSchema, TableSchema][]> {
        return this.fetchJsonObservable<[TableSchema, TableSchema, TableSchema, TableSchema][]>('/verbaler/drafts?deal_id=' + deal_id);
    }

    async getActiveCompaniesBasicInfo(): Promise<Array<any>> {
        return await this.fetchJson<Array<CompanyData>>('/verbaler/get-companies-basic-info', 'GET');
    }

    getCompaniesTable(filters: Filters): Observable<[SelectComponent, TableSchema]> {
        const queryParams = this.formatQueryParams(filters);

        return this.fetchJsonObservable<[SelectComponent, TableSchema]>(`/verbaler/companies${queryParams}`, 'GET');
    }

    async getVerbalDraft(uuid: string): Promise<VerbalerDraftConfig> {
        return await this.fetchJson<VerbalerDraftConfig>(`/verbaler/get-draft?uuid=${uuid}`, 'GET');
    }

    async generateDraftOutputs(draft_info: VerbalerDraftConfig, companyId: string|null = null): Promise<VerbalerDraftConfig> {
        const param = (companyId !== null) ? `?company_id=${companyId}` : '';
        return await this.fetchJson<VerbalerDraftConfig>(`/verbaler/calculate-draft-outputs${param}`, 'POST', draft_info);
    }

    async updateVerbalDraft(uuid: string, draft_info: VerbalerDraftConfig): Promise<string> {
        return await this.fetchJson<string>(`/verbaler/update-draft?uuid=${uuid}`, 'POST', draft_info);
    }

    async updateVerbalStatus(draft_id: string, value: string): Promise<any> {
        return await this.fetchJson<any>(`/verbaler/update-verbal-status?draft_id=${draft_id}&status=${value}`);
    }

    async getMailtoLink(clientName: string, loanNumber: number, email: string): Promise<string> {
        return await this.fetchJson<string>(`/verbaler/get-mailto-link?client_name=${clientName}&loan_number=${loanNumber}&email=${email}`);
    }
}
