import { pushNotification, utils, Widget } from '@truenorthmortgage/olympus';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FormHelper from '../../components/form-helper/form-helper.component';
import { FormSchema } from '../../models/schemas/form-schema';
import { SERVICES } from '../../services';
import { ComponentService } from '../../services/component.service';
import { ReportService } from '../../services/report.service';

const RequestForFunding = () => {
    const reportService = utils.injection.useInjection<ReportService>(SERVICES.ReportService);
    const componentService = utils.injection.useInjection<ComponentService>(SERVICES.ComponentService);
    const [form, setForm] = useState<FormSchema>();
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        return reportService.getRequestForFunding().subscribe({
            next: setForm,
            error: (e) => {
                dispatch(pushNotification({ class: 'error', message: e.toString() }));
            }
        });
    }, [setForm]);

    useEffect(() => {
        const subscription = loadData();

        return () => {
            subscription.unsubscribe();
        };
    }, [loadData]);

    const onSubmit = useCallback((response: {__blob_data: string, file_name: string}) => {
        if (typeof response === 'string') {
            dispatch(pushNotification({ class: 'success', message: response}));
        } else {
            componentService.createDownload(response.__blob_data, response.file_name);
        }
    }, []);

    return form ? 
        <Widget>
            <FormHelper schema={form} onSubmit={onSubmit} /> 
        </Widget>
        : null;
};

export default RequestForFunding;
