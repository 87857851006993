import { AuthService } from './auth.service';
import { inject } from 'inversify';
import { SERVICES } from '.';
import { injectable } from 'inversify';
import {Observable, switchMap, tap, map, from, zip, of, lastValueFrom, Subscription} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { Dispatch } from 'react';
import { pushNotification } from '@truenorthmortgage/olympus';
import { Filters } from './report.service';

@injectable()
export class HttpService {
    protected apiUrl: string;

    @inject(SERVICES.AuthService) protected authService: AuthService | null = null;

    constructor() {
        this.apiUrl = HttpService.getApiUrl();
    }

    async fetchJson<T = any>(path: string, method = 'GET', data: any = null): Promise<T> {
        const [status, body] = await HttpService.executeFetchRequest(
            path,
            this.authService!.getAuthorizationHeaders(data instanceof FormData ? { contentType: null } : undefined),
            method,
            data
        );
        this.authService!.validateRequest(status, body);

        return body as T;
    }

    fetchJsonObservable<T = any>(path: string, method = 'GET', data: any = null): Observable<T> {
        return HttpService.executeFetchRequestObservable(
            path,
            this.authService!.getAuthorizationHeaders(data instanceof FormData ? { contentType: null } : undefined),
            method,
            data
        ).pipe(
            tap(([status, body]) => {
                this.authService!.validateRequest(status, body);
            }),
            map(([_status, body]) => body)
        );
    }

    public static getApiUrl(): string {
        return window.REACT_APP_SITE_URL_ZEUS;
    }

    public static executeFetchRequestObservable(
        path: string,
        headers: Record<string, string> = {},
        method = 'GET',
        data: any = null
    ): Observable<[number, any]> {
        if (path.length > 0 && !path.startsWith('/')) {
            path = '/' + path;
        }

        return fromFetch(`${this.getApiUrl()}${path}`, {
            credentials: 'include',
            method,
            body: data instanceof FormData ? data : (data ? JSON.stringify(data) : undefined),
            headers
        }).pipe(
            switchMap(response => zip(
                of(response.status), 
                response.headers.get('content-type')?.includes('application/json') ? from(response.json()) : from(response.blob()),
                of(response.headers.get('content-disposition'))
            )),
            map(([status, body, contentDisposition]) => {
                if (body instanceof Blob) {
                    body = { __blob_data: URL.createObjectURL(body), file_name: contentDisposition?.split(';')[1].split('=')[1].replace(/['"]/g, '') };
                }
                if (status === 200 && body && body.status_code !== undefined) {
                    if (body.status_code === 200) {
                        return [200, body.data ?? body.message];
                    } else if (body.message) {
                        return [body.status_code, body.message];
                    }
                }
                return [status, body];
            })
        );
    }

    public static async executeFetchRequest(
        path: string,
        headers: Record<string, string> = {},
        method = 'GET',
        data: any = null
    ): Promise<[number, any]> {
        return lastValueFrom(this.executeFetchRequestObservable(path, headers, method, data));
    }

    public static subscribe(observable: Observable<any>, dispatch: Dispatch<any>, next?: (data: any) => void): Subscription {
        return observable.subscribe({
            next,
            error: (e) => {
                dispatch(
                    pushNotification({ class: 'error', message: e.message })
                );
            }
        });
    }
 
    /**
     * Generate query parameters string from Filters object
     * @param filters - Filters object containing query parameters
     * @param hasExistingParams - Boolean indicating whether existing parameters exist in the URL
     * @returns Query parameters string
     */
    formatQueryParams(filters: Filters, hasExistingParams = false): string {
        let queryParams = '';
        if (filters) {
            queryParams = ((hasExistingParams) ? '&' : '?') + Object.keys(filters).map(key => `${key}=${filters[key]}`).join('&');
        }
        return queryParams;
    }
}
