import { Dispatch } from '@reduxjs/toolkit';
import { BasicModalHandle, FileComponentValue, Form, pushNotification, utils } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { catchError, defer, of, switchMap, tap } from 'rxjs';
import { ValidationError } from '../../errors/validation-error';
import { ComponentSchema, FormSchema } from '../../models/schemas/form-schema';
import { SERVICES } from '../../services';
import { ComponentService } from '../../services/component.service';
import { HttpService } from '../../services/http.service';
import ComponentHelper from '../component-helper/component-helper.component';
import { Accordion } from '../../models/accordions/accordion';
import { isAccordionItemData } from '../accordion-helper/accordion-helper.component';

export interface FormHelperProps {
    schema: FormSchema;
    onSubmit: (data?: any) => void;
    onCustomPayload?: (payload: Record<string, any>) => Promise<Record<string, any>>;
    parentRef?: RefObject<BasicModalHandle>;
    triggerOnLoad?: boolean;
}

export type ChangeHandler = ((s: string | null) => void) | ((s: string[]) => void) | ((s: FileComponentValue) => void) | ((s: string | boolean | null) => void);
export type OnChangeMap = Record<string, ChangeHandler>;

export const displayErrors = (e: any, setErroredInputs: React.Dispatch<React.SetStateAction<string[]>>, dispatch: Dispatch<any>) => {
    if (e instanceof ValidationError) {
        dispatch(pushNotification({ class: 'error', message: e.message }));
        setErroredInputs(Object.keys(e.errors));
    } else {
        dispatch(pushNotification({ class: 'error', message: e.message }));
    }
};

const FormHelper: FC<FormHelperProps> = ({ schema, onSubmit, onCustomPayload, parentRef, triggerOnLoad = true }) => {
    const [payload, setPayload] = useState<Record<string, string | FileComponentValue>>({});
    const [erroredInputs, setErroredInputs] = useState<string[]>([]);
    const httpService = utils.injection.useInjection<HttpService>(SERVICES.HttpService);
    const componentService = utils.injection.useInjection<ComponentService>(SERVICES.ComponentService);
    const dispatch = useDispatch();

    const parseAccordion = (accordion: Accordion, onChangeMap: OnChangeMap) => {
        accordion.accordion_items.map(item => {
            if (isAccordionItemData(item)) {
                onChangeMap[item.label] = (value: string | null) => {
                    // check if data is different to avoid infinite re-renders
                    setPayload(payload => {
                        item.checked = value === 'true';
                        return payload[item.label] !== value ? Object.assign({}, payload, { [item.label ?? '']: value }) : payload;
                    });
                };
            } else {
                parseAccordion(item, onChangeMap);
            }
            return item;
        });
    };

    const mapOnChangeToComponent = useCallback((onChangeMap: OnChangeMap, component: ComponentSchema) => {
        if ('name' in component && component.name && component.type === 'file') {
            onChangeMap[component.name] = (value: FileComponentValue) => {
                setPayload(payload => {
                    return Object.assign({}, payload, { [component.name ?? '']: value });
                });
            };
        } else if ('name' in component && component.name && component.type === 'accordions') {
            component.accordions.forEach(accordion => {
                parseAccordion(accordion, onChangeMap);
            });
        } else if ('name' in component && component.name) {
            onChangeMap[component.name] = (value: string | null) => {
                setPayload(payload => {
                    return Object.assign({}, payload, { [component.name ?? '']: value });
                });
            };
        }

        if (component.type === 'hidden') {
            setPayload(payload => {
                return Object.assign({}, payload, { [component.name ?? '']: component.value });
            });
        }
        return onChangeMap;
    }, []);

    const reduceComponent = useCallback((onChangeMap: OnChangeMap, component: ComponentSchema): OnChangeMap => {
        if ('type' in component && component.type == 'field-group') {
            return component.components.reduce((onChangeMap, fieldComponent) => {
                return reduceComponent(onChangeMap, fieldComponent);
            }, onChangeMap);
        }
        return mapOnChangeToComponent(onChangeMap, component);
    }, [mapOnChangeToComponent]);

    const onChangeMap = useMemo(() => {
        return schema.components.reduce(reduceComponent, {});
    }, [schema, setPayload, reduceComponent]);

    const onSubmitClick = useCallback((event: any) => {
        return defer(async () => {
            const submittedPayload = Object.assign({}, payload);
            if (onCustomPayload) {
                const customPayload = await onCustomPayload(submittedPayload);
                Object.assign(submittedPayload, customPayload);
            }
    
            return componentService.getFormat(submittedPayload) === 'form-data'
                ? componentService.createFormData(submittedPayload)
                : submittedPayload;
        }).pipe(
            tap(formattedPayload => { 
                if (schema.validatePayload) {
                    schema.validatePayload(formattedPayload);
                }
            }),
            switchMap(formattedPayload => {
                if (event && event.cancel) { 
                    onSubmit();
                    return of();
                }
                const action = event && event.action ? event.action : schema.action;
                return httpService.fetchJsonObservable(action, 'POST', formattedPayload)
                    .pipe(
                        tap({
                            next: data => {
                                setErroredInputs([]);
                                onSubmit(data);
                            }
                        }),
                        catchError(e => {
                            displayErrors(e, setErroredInputs, dispatch);
                            return of();
                        })
                    );
            })
        );
    }, [schema, payload, dispatch, onSubmit, onCustomPayload, setErroredInputs, componentService, httpService]);

    const updatedSchema = useMemo(() => {
        return componentService.customizeSchema(schema);
    }, [schema, componentService, dispatch]);

    return (
        <Form>
            {updatedSchema.components.map((component, index) => {
                const hasErrors = 'name' in component && !!component.name && erroredInputs.includes(component.name);
                // populating any submission query params
                if (component.type === 'buttons') {
                    component.buttons.map((button) => {
                        if ('submit_query_params' in button && button.submit_query_params !== null) componentService.addSubmitQueryParams(updatedSchema, button);
                    });
                }
                return (
                    <ComponentHelper key={index} schema={component} onSubmit={onSubmitClick} onChangeMap={onChangeMap} parentRef={parentRef} triggerOnLoad={triggerOnLoad} hasErrors={hasErrors} />
                );
            })}
        </Form>
    );
};

export default FormHelper;

