import { BasicModalHandle, pushNotification, utils, Widget } from '@truenorthmortgage/olympus';
import { FC, RefObject, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import FormHelper from '../components/form-helper/form-helper.component';
import { FormSchema } from '../models/schemas/form-schema';
import AddSecurityKey from './add-security-key.component';
import AssignEmailDealForm from './assign-email-deal-form.component';
import DealHelocForm from './deal-heloc-form.component';
import CesAgent from './ces-agent-modal.component';
import PropertyEdit from './property-edit.component';
import RenewConciergeLoan from './renew-concierge-loan.component';
import ThinkerBonusPeriods from './thinker-bonus-periods.component';
import UpdateDealStatusForm from './update-deal-status-form.component';
import UpgradePremierCloserForm from './upgrade-premier-closer-form.component';
import UpgradePremierThinkerForm from './upgrade-premier-thinker-form.component';
import CesAgentNotes from './ces-notes-form-modal.component';
import { ComponentService } from '../services/component.service';
import { SERVICES } from '../services';

export interface FormModalProps {
    data?: FormSchema,
    parentRef?: RefObject<BasicModalHandle>,
}

const FormModal: FC<FormModalProps> = ({ data, parentRef }) => {
    const dispatch = useDispatch();
    const componentService = utils.injection.useInjection<ComponentService>(SERVICES.ComponentService);

    useEffect(() => {
        if (data?.form_title && parentRef?.current) {
            parentRef.current.setTitle(data.form_title);
        }
    }, [data?.title, parentRef?.current]);

    const onSubmit = useCallback((message: any) => {
        if (typeof message === 'string') {
            dispatch(pushNotification({ class: 'success', message: message }));
            // typeof null is also an object, so ensure that message is still defined
        } else if (message !== null && typeof message === 'object' && '__blob_data' in message) {
            componentService.createDownload(message.__blob_data, message.file_name);
        }
        parentRef?.current?.close({submit: true});
    }, [dispatch, parentRef]);

    const formElement = useMemo(() => {
        if (data) {
            const loadJs = Array.isArray(data) ? (data as FormSchema[])[(data as FormSchema[]).length - 1].load_js : data.load_js;

            switch (loadJs) {
            case 'upgrade-premier-closer-form.js':
                return (<UpgradePremierCloserForm schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'upgrade-premier-thinker-form.js':
                return (<UpgradePremierThinkerForm schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'create-note-form.js':
                return (<UpdateDealStatusForm schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'assign-email-deal-form.js':
                return (<AssignEmailDealForm schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'ces-agent-form.js':
                return(<CesAgent schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'deal-heloc-form.js':
                return(<DealHelocForm schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'add-2fa-key.js':
                return (<AddSecurityKey schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'thinker-bonus-periods.js':
                return (<ThinkerBonusPeriods schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'ces-notes-form.js':
                return(<CesAgentNotes schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'property-edit.js':
                return (<PropertyEdit schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            case 'renew-concierge-loan.js':
                return (<RenewConciergeLoan schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            default: 
                return (<FormHelper schema={data} onSubmit={onSubmit} parentRef={parentRef} />);
            }
        }
        return null;
    }, [data, onSubmit, parentRef]);

    return data && parentRef ? (
        <Widget>
            {formElement}
        </Widget>
    ) : null;
};

export default FormModal;
