import { Deal, DealUpgradePrice, DocumentsData, LeadsData, MemoData } from '../models/deals/deal';
import { HttpService } from './http.service';
import { injectable } from 'inversify';
import { DealHeader } from '../models/deals/header';
import { Accordion } from '../models/accordions/accordion';
import { RequestInstructions } from '../models/deals/request-instructions';
import { TableSchema } from '../models/schemas/table-schema';
import { ComponentSchema, FormSchema } from '../models/schemas/form-schema';
import { DealsQueueData } from '../models/deals/deals-queue';
import { DealNotesTimeline } from '../models/deals/notes';
import ActionButtonsHelper from '../components/action-buttons-helper/action-buttons-helper.component';
import { HistoryTimeline } from '../models/deals/history';
import { FundingData } from '../models/deals/funding';
import ContainerModal from '../components/container-modal/container-modal.component';
import { zip, map, Observable, tap } from 'rxjs';
import { CustomizeSchema } from './interfaces/customize-schema';
import { ActionHeaderSchema } from '../models/schemas/action-header';
import { DealConciergeProps } from '../pages/deals/concierge/deal-concierge.component';
import { ActionButton } from '../models/accordions/action-button';

@injectable()
export class DealService extends HttpService implements CustomizeSchema {
    getDeal(dealId: string): Observable<Deal> {
        return zip(
            this.getHeader(dealId),
            this.getApplicant(dealId),
            this.getThinkDealData(dealId),
            this.getDealData(dealId),
            this.getDealConcierge(dealId),
            this.getDealHeloc(dealId),
            this.getDealProperty(dealId),
        ).pipe(map(([
            header,
            applicant,
            status,
            dealData,
            concierge,
            heloc,
            property
        ]) => ({
            header,
            applicant,
            status,
            dealData,
            concierge,
            heloc,
            property
        })));
    }

    getHeader(dealId: string): Observable<DealHeader> {
        return this.fetchJsonObservable<DealHeader>(`/deals/details-header?deal_id=${dealId}`).pipe(tap(schema => this.addAccordionModal(schema)));
    }
    getApplicant(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/details-applicants?deal_id=${dealId}`);
    }

    getThinkDealData(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/details-think?deal_id=${dealId}`);
    }

    getDealData(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/details-deal?deal_id=${dealId}`);
    }

    getDealConcierge(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/details-concierge?deal_id=${dealId}`);
    }

    getDealHeloc(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/details-heloc?deal_id=${dealId}`);
    }

    getDealProperty(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/details-property?deal_id=${dealId}`);
    }

    getDealConditions(dealId: string): Observable<[TableSchema, ActionButton, ActionButton, ActionButton, TableSchema]> {
        return this.fetchJsonObservable<[TableSchema, ActionButton, ActionButton, ActionButton, TableSchema]>(`/deals/conditions?deal_id=${dealId}`);
    }

    getRequestInstructions(dealId: string): Promise<RequestInstructions> {
        return this.fetchJson<RequestInstructions>(`/deals/request-instructions?deal_id=${dealId}`);
    }

    postRequestInstructions(dealId: string): Promise<RequestInstructions> {
        return this.fetchJson<RequestInstructions>(`/deals/request-instructions?deal_id=${dealId}`);
    }

    getPlaceDealOnHold(dealId: string): Promise<any> {
        return this.fetchJson<any>(`/deals/update-deal-on-hold?deal_id=${dealId}`);
    }

    postPlaceDealOnHold(dealId: string): Promise<any> {
        return this.fetchJson<any>(`/deals/update-deal-on-hold?deal_id=${dealId}`);
    }

    postHedgeNow(dealId: string): Promise<any> {
        return this.fetchJson<any>(`/deals/hedge-now?deal_id=${dealId}`);
    }

    getUpdateDealStatus(dealId: string, type = 'deal-note'): Promise<any> {
        return this.fetchJson<any>(`/notes/create?deal_id=${dealId}&type=${type}`);
    }

    editDealStatus(dealId: string, type = 'deal-note'): Promise<any> {
        return this.fetchJson<any>(`/notes/create?deal_id=${dealId}&type=${type}`);
    }

    editHeloc(dealId: string): Promise<any> {
        return this.fetchJson<any>(`/deals/edit-heloc?deal_heloc_id=${dealId}`);
    }

    calculateServicingFee(helocRate: string | null, closingDate: string | null): Promise<string> {
        return this.fetchJson<string>(`/deal-helocs/calculate-servicing-fee?heloc_rate_prime_plus=${helocRate}&closing_date=${closingDate}`);
    }

    calculateCloserUpgradePrice(dealId: string): Promise<DealUpgradePrice> {
        return this.fetchJson<DealUpgradePrice>(`/deals/calculate-closer-upgrade-price?deal_id=${dealId}`);
    }

    getSimpleDealAccordion(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/simple-deal-accordion?deal_id=${dealId}`);
    }

    getLeadContactInfoAccordion(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/lead-contact-info-accordion?deal_id=${dealId}`);
    }

    getLeadApplicantInfoAccordion(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/lead-applicant-info-accordion?deal_id=${dealId}`);
    }

    getLeadCoApplicantInfoAccordion(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/lead-co-applicant-info-accordion?deal_id=${dealId}`);
    }

    getLeadMortgageInfoAccordion(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/lead-mortgage-info-accordion?deal_id=${dealId}`);
    }

    getLeadAccordion(dealId: string): Observable<any> {
        return zip(
            this.getLeadContactInfoAccordion(dealId),
            this.getLeadApplicantInfoAccordion(dealId),
            this.getLeadCoApplicantInfoAccordion(dealId),
            this.getLeadMortgageInfoAccordion(dealId),
        );
    }

    getNotestoDocSpecialistAccordion(dealId: string): Observable<[ActionHeaderSchema, ComponentSchema]> {
        return this.fetchJsonObservable<[ActionHeaderSchema, ComponentSchema]>(`/deals/document-specialist-notes?deal_id=${dealId}`);
    }

    getDocumentData(dealId: string): Observable<DocumentsData> {
        return this.fetchJsonObservable<DocumentsData>(`/deals/documents?deal_id=${dealId}`);
    }

    getDealsQueue(): Observable<DealsQueueData> {
        return this.fetchJsonObservable<DealsQueueData>('/deals/zeus-deals-queue-status');
    }

    getDealNotes(dealId: string): Observable<DealNotesTimeline> {
        return this.fetchJsonObservable<DealNotesTimeline>(`/deals/notes?deal_id=${dealId}`);
    }

    getDealMessages(dealId: string): Observable<DealNotesTimeline[]> {
        return this.fetchJsonObservable<DealNotesTimeline[]>(`/deals/messages?deal_id=${dealId}`);
    }

    getFunding(dealId: string): Observable<FundingData> {
        return this.fetchJsonObservable<FundingData>(`/deals/funding?deal_id=${dealId}`);
    }

    getDealRanking(dealId: string): Observable<Accordion> {
        return this.fetchJsonObservable<Accordion>(`/deals/rating?deal_id=${dealId}`);
    }

    getConciergeLoanData(dealId: string): Observable<DealConciergeProps> {
        return this.fetchJsonObservable<DealConciergeProps>(`/deals/concierge-loan?deal_id=${dealId}`);
    }

    searchRates(payload: Record<string, any>): Promise<TableSchema> {
        return this.fetchJson<TableSchema>('/rates/wizard', 'POST', payload);
    }

    createMessage(modelSegment: string, type: string, primaryKey: string): Promise<FormSchema> {
        return this.fetchJson<FormSchema>(`/messages/create?model_segment=${modelSegment}&type=${type}&primary_key=${primaryKey}`);
    }

    getOverview(): Observable<[TableSchema, FormSchema, TableSchema]> {
        return this.fetchJsonObservable<[TableSchema, FormSchema, TableSchema]>('/deals/overview');
    }

    getHistory(dealId: string): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>(`/deals/history?deal_id=${dealId}`);
    }

    seachDeals(dealId?: string): Observable<TableSchema> {
        return this.fetchJsonObservable<TableSchema>(`/deals/search?search_deals_table_query${dealId ? '=' + dealId : ''}`);
    }

    saveMemo(memo: MemoData): Promise<any> {
        return this.fetchJson<any>('/document-section-memos/save', 'POST', memo);
    }

    saveSectionName(payload: Record<string, string | number | null>) {
        return this.fetchJson<any>('/document-section-memos/save-section-name', 'POST', payload);
    }

    setCloserStatus(payload: Record<string, string>): Promise<any> {
        return this.fetchJson<any>('/documents/set-closer-status', 'POST', payload);
    }

    getDealDocumentsBatchUpdateOptions(): Observable<{label: string, value: number}[]> {
        return this.fetchJsonObservable<{label: string, value: number}[]>('/documents/get-closer-status-list');
    }

    executeDealDocumentBatchUpdate(payload: { document_ids: number[]; closer_status: string; }): Observable<any> {
        return this.fetchJsonObservable<FormSchema>('/documents/bulk-set-closer-status', 'POST', payload);
    }

    addAccordionModal(schema: DealHeader | Accordion, onClose?: (data?: any) => void) {
        if (schema === null) {
            return;
        }

        if ('action_buttons' in schema && schema.action_buttons) {
            schema.action_buttons.forEach(button => {
                if (button.modal) {
                    button.modalElement = (<ContainerModal />);
                    button.modalLoadProps = async () => ({ data: await this.fetchJson<FormSchema>(button.uri) });
                    button.modalHeader = button.label;
                    button.modalOnClose = onClose;
                }
            });
        }

        if ('action_items' in schema && schema.action_items) {
            schema.action_items.forEach(item => {
                if (item.type === 1) {
                    item.modal = {
                        element: (<ContainerModal />),
                        loadProps: async () => ({ data: await this.fetchJson<FormSchema>(item.uri) }),
                        header: item.label,
                        onClose
                    };
                }
            });
        }
    }

    updateDealThinkerStatus(deal_id: string, value: string) {
        return this.fetchJson<any>(`/thinker/update-deal-thinker-status?deal_id=${deal_id}&status=${value}`);
    }

    getTimelineItemButtons(timeline: DealNotesTimeline | HistoryTimeline | null, onModalClose?: (data?: any) => void) {
        if (!timeline) {
            return null;
        }
        return timeline.timeline_items.map((item, index) => {
            if (!item.action_buttons || item.action_buttons.length === 0) {
                return null;
            }
            return (
                <ActionButtonsHelper key={index} schema={item.action_buttons} onModalClose={onModalClose} />
            );
        });
    }

    customizeSchema(schema: FormSchema) {
        if (schema.action) {
            if(schema.action.startsWith('/documents/set-status-details') || schema.action.startsWith('/documents/confirm-document-not-needed')) {
                schema.components.forEach(component => {
                    if (component.type === 'buttons') {
                        const cancel = component.buttons.find(b => b.class === 'cancel');
                        if (cancel) {
                            cancel.modalCloseData = { cancel: true };
                        }
                    }
                });
            }
        }
    }

    createConfirm(draftId: string, status: string): Promise<FormSchema> {
        return this.fetchJson<FormSchema>(`/verbaler/confirm-status-change?draft_id=${draftId}&status=${status}`);
    }
}

