const en: Record<string, string> = {
    'Keep me Logged in': 'Keep me Logged in',
    'Login': 'Login',
    'Forgot Your Password?': 'Forgot Your Password?',
    'Email': 'Email',
    'Password': 'Password',
    'Submit a Deal': 'Submit a Deal',
    'My Deals': 'My Deals',
    'My THINK Financial Deals - Overview': 'My THINK Financial Deals - Overview',
    'Underwriting Deals - Overview': 'Underwriting Deals - Overview',
    'Details': 'Details',
    'Deal #:deal_id - Details': 'Deal #:deal_id - Details',
    'Deal #:deal_id - Lead': 'Deal #:deal_id - Lead',
    'Deal #:deal_id - Documents': 'Deal #:deal_id - Documents',
    'Deal #:deal_id - Notes': 'Deal #:deal_id - Notes',
    'Deal #:deal_id - Messages': 'Deal #:deal_id - Messages',
    'Deal #:deal_id - Funding': 'Deal #:deal_id - Funding',
    'Deal #:deal_id - Rating': 'Deal #:deal_id - Rating',
    'Deal #:deal_id - Concierge Loan': 'Deal #:deal_id - Concierge Loan',
    'Deal #:deal_id - Conditions': 'Deal #:deal_id - Conditions',
    'Deal #:deal_id - History': 'Deal #:deal_id - History',
    'Documents': 'Documents',
    'Notes': 'Notes',
    'Messages': 'Messages',
    'Funding': 'Funding',
    'Rating': 'Rating',
    'Submit a New Deal to THINK': 'Submit a New Deal to THINK',
    'Underwriting': 'Underwriting',
    'EMPTY': 'EMPTY',
    'Priority Inbox': 'Priority Inbox',
    'Deal Id': 'Deal Id',
    'From': 'From',
    'Action': 'Action',
    'Requesting': 'Requesting',
    'Think #': 'Think #',
    'Heloc #': 'Heloc #',
    'Applicants': 'Applicants',
    'Insurer': 'Insurer',
    'Status': 'Status',
    'Age of Deal': 'Age of Deal',
    'Agent': 'Agent',
    'Pay': 'Pay',
    'Actions': 'Actions',
    'Docs to Review': 'Docs to Review',
    'Applicant': 'Applicant',
    'Oldest Doc': 'Oldest Doc',
    'Cof': 'Cof',
    'Closing': 'Closing',
    'Type': 'Type',
    'Docs Reviewed': 'Docs Reviewed',
    'Cumulative Document Type': 'Cumulative Document Type',
    'My Deals in Underwriting': 'My Deals in Underwriting',
    'My Deals with Unreviewed Documents': 'My Deals with Unreviewed Documents',
    'My Closing Deals': 'My Closing Deals',
    'My THINK Deals': 'My THINK Deals',
    'Tracker Id': 'Tracker Id',
    'Hedge': 'Hedge',
    'Client': 'Client',
    'Think Deal Status': 'Think Deal Status',
    'Thinker Premier': 'Thinker Premier',
    'Thinker Status': 'Thinker Status',
    'Closer Premier': 'Closer Premier',
    'Closer Status': 'Closer Status',
    'Days To Closing': 'Days To Closing',
    'Hedge Now?': 'Hedge Now?',
    'Upgrade': 'Upgrade',
    'Next Deal': 'Next Deal',
    'Oldest doc': 'Oldest doc',
    'Underwriter': 'Underwriter',
    'Cumulative Document Time': 'Cumulative Document Time',
    'Closer Deals': 'Closer Deals',
    'Closer Deals - Overview': 'Closer Deals - Overview',
    'Document Specialist Dashboard': 'Document Specialist Dashboard',
    'Document Specialist Queue': 'Document Specialist Queue',
    'Dashboard': 'Dashboard',
    'Deal Status': 'Deal Status',
    'Empty': 'Empty',
    'No deals': 'No deals',
    'What type of transaction?': 'What type of transaction?',
    'Wizard Mode': 'Wizard Mode',
    'Rate Wizard': 'Rate Wizard',
    'Insured (Quick View)': 'Insured (Quick View)',
    'Purchase Price': 'Purchase Price',
    'Down Payment (as $)': 'Down Payment (as $)',
    'Down Payment (as %)': 'Down Payment (as %)',
    'LTV': 'LTV',
    'Amortization': 'Amortization',
    'Possession Date': 'Possession Date',
    'Number of Days': 'Number of Days',
    'Beacon Score': 'Beacon Score',
    'Property Province': 'Property Province',
    'City': 'City',
    'Property Use': 'Property Use',
    'Term': 'Term',
    'Purchase': 'Purchase',
    'Switch': 'Switch',
    'Refinance': 'Refinance',
    'Northwest Territories': 'Northwest Territories',
    'Yukon': 'Yukon',
    'Alberta': 'Alberta',
    'British Columbia': 'British Columbia',
    'Manitoba': 'Manitoba',
    'Saskatchewan': 'Saskatchewan',
    'Ontario': 'Ontario',
    'Quebec': 'Quebec',
    'New Brunswick': 'New Brunswick',
    'Newfoundland and Labrador': 'Newfoundland and Labrador',
    'Nova Scotia': 'Nova Scotia',
    'Nunavut': 'Nunavut',
    'Prince Edward Island': 'Prince Edward Island',
    'Calgary': 'Calgary',
    'Vancouver': 'Vancouver',
    'Hamilton': 'Hamilton',
    'Ottawa': 'Ottawa',
    'Toronto': 'Toronto',
    'Primary': 'Primary',
    'Secondary/Vacation': 'Secondary/Vacation',
    'Rental': 'Rental',
    '2 Year Fixed': '2 Year Fixed',
    '3 Year Fixed': '3 Year Fixed',
    '5 Year Fixed': '5 Year Fixed',
    '5 Year Variable': '5 Year Variable',
    'Search': 'Search',
    'Rate Wizard - Search': 'Rate Wizard - Search',
    'Submission Date': 'Submission Date',
    'Other': 'Other',
    'Current Uncompleted Tasks': 'Current Uncompleted Tasks',
    'Daily Stats': 'Daily Stats',
    'Order': 'Order',
    'Task': 'Task',
    'Assigned Funder': 'Assigned Funder',
    'Weight': 'Weight',
    'Deal Closing Date': 'Deal Closing Date',
    'Date Created': 'Date Created',
    'Think Loan #': 'Think Loan #',
    'Funders': 'Funders',
    'Funders - Management': 'Funders - Management',
    'Funders Management': 'Funders Management',
    'Funders - Reports': 'Funders - Reports',
    'Funders Reports': 'Funders Reports',
    'Funders - Task Queue': 'Funders - Task Queue',
    'Underwriting Deals - Conditions': 'Underwriting Deals - Conditions',
    '{month} Tasks': '{month} Tasks',
    'Pay: ${pay} | Tasks: {activeTasks} ({totalTasks} Today)': 'Pay: ${pay} | Tasks: {activeTasks} ({totalTasks} Today)',
    'No Assigned Tasks': 'No Assigned Tasks',
    'Tasks': 'Tasks',
    'Funder Deals - Overview': 'Funder Deals - Overview',
    'Funders - Statistics': 'Funders - Statistics',
    'Statistics': 'Statistics',
    'Tasks Completed Today: {tasksCompleted}': 'Tasks Completed Today: {tasksCompleted}',
    'Task Queue': 'Task Queue',
    'Funder': 'Funder',
    'Month': 'Month',
    'Today': 'Today',
    'Daily Average (This Month)': 'Daily Average (This Month)',
    '*These results specifically exclude \'Assignment\' Tasks*': '*These results specifically exclude \'Assignment\' Tasks*',
    'Heloc Think Loan #': 'Heloc Think Loan #',
    'Closing Date': 'Closing Date',
    'View Deal': 'View Deal',
    'Deal Search': 'Deal Search',
    'Deals': 'Deals',
    '-- Select Field --': '-- Select Field --',
    'ID': 'ID',
    'THINK Loan #': 'THINK Loan #',
    'Ascending': 'Ascending',
    'Descending': 'Descending',
    'Clear': 'Clear',
    'Sort': 'Sort',
    'Deal status has been updated!': 'Deal status has been updated!',
    'Deal declined successfully.': 'Deal declined successfully.',
    'Thinker Decline - Loan': 'Thinker Decline - Loan',
    'Are you sure you want to decline this deal? An email will be sent to the deal agent notifying them.': 'Are you sure you want to decline this deal? An email will be sent to the deal agent notifying them.',
    'Reason for Decline:': 'Reason for Decline:',
    'Decline': 'Decline',
    'Thinker Approve - Loan': 'Thinker Approve - Loan',
    'Are you sure you want to approve this deal? An email will be sent to the deal agent notifying them.': 'Are you sure you want to approve this deal? An email will be sent to the deal agent notifying them.',
    'Approve': 'Approve',
    'Cancel': 'Cancel',
    'Deal approved successfully.': 'Deal approved successfully.',
    'Reason for Request': 'Reason for Request',
    'Send Request': 'Send Request',
    'Request Transfer': 'Request Transfer',
    'Manager Deals': 'Manager Deals',
    'Admin': 'Admin',
    'Admin - Users': 'Admin - Users',
    'Users': 'Users',
    'Security': 'Security',
    'Instructions requested': 'Instructions requested',
    'Deal modified successfully': 'Deal modified successfully',
    'Deal Submitted to THINK': 'Deal Submitted to THINK',
    'Think Submission Cancelled' : 'Think Submission Cancelled',
    'Unset Persona': 'Unset Persona',
    'Current Mortgage': 'Current Mortgage',
    'Requested Mortgage': 'Requested Mortgage',
    'Remaining Amortization': 'Remaining Amortization',
    'Calculating price upgrade...': 'Calculating price upgrade...',
    'Upgrade price:': 'Upgrade price:',
    '(Upgrade price is valid for {minutes} minutes: {timer} remaining)': '(Upgrade price is valid for {minutes} minutes: {timer} remaining)',
    'Out of time to submit at the current Premier price, \'Upgrade\' again for a new price.': 'Out of time to submit at the current Premier price, \'Upgrade\' again for a new price.',
    'Current Mortgage Insured?': 'Current Mortgage Insured?',
    'Home Value': 'Home Value',
    'Yes': 'Yes',
    'No': 'No',
    'Set yourself': 'Set yourself',
    'AWAY': 'AWAY',
    'AVAILABLE': 'AVAILABLE',
    'Settings': 'Settings',
    'User Settings': 'User Settings',
    'User Settings updated': 'User Settings updated',
    'Please choose a sort field for all sort options before adding more': 'Please choose a sort field for all sort options before adding more',
    'When you have your security key ready, press the button below to verify your login.': 'When you have your security key ready, press the button below to verify your login.',
    'Logout': 'Logout',
    'I\'m Ready': 'I\'m Ready',
    'Selected documents:': 'Selected documents:',
    '(+{count} more)': '(+{count} more)',
    'Update': 'Update',
    'Dark mode': 'Dark mode',
    'Queue': 'Queue',
    'Conditions': 'Conditions',
    '6 Month - Insured': '6 Month - Insured',
    'Thinker Pods': 'Thinker Pods',
    'Manage Thinker Pods': 'Manage Thinker Pods',
    'Overview': 'Overview',
    'Concierge Loan': 'Concierge Loan',
    'Reports': 'Reports',
    'Thinker Pod Report': 'Thinker Pod Report',
    'Thinker Bonus Report': 'Thinker Bonus Report',
    'Agent Rating Report': 'Agent Rating Report',
    'Request for Funding': 'Request for Funding',
    'History' : 'History',
    'All Actions': 'All Actions',
    'Funder Deal Report': 'Funder Deal Report',
    'Admin - Mailing Lists': 'Admin - Mailing Lists',
    'Mailing Lists': 'Mailing Lists',
    'Manual Commitment': 'Manual Commitment',
    'CES': 'CES',
    'Lead': 'Lead',
    'Save': 'Save',
    'Notes added successfully.': 'Notes added successfully.',
    'Doc Specialist Actions': 'Doc Specialist Actions',
    '1 Year Fixed': '1 Year Fixed',
    'Grab A Lead': 'Grab A Lead',
    'CES Report': 'CES Report',
    'RBAC': 'RBAC',
    'User Roles': 'User Roles',
    'User Permissions': 'User Permissions',
    'Role Permissions': 'Role Permissions',
    'Roles': 'Roles',
    'Tags': 'Tags',
    'Dead Leads Report': 'Dead Leads Report',
    'Dead No Contact Leads': 'Dead No Contact Leads',
    'Concierge Manager Deals': 'Concierge Manager Deals',
    'Deal #:deal_id - Income Request': 'Deal #:deal_id - Income Request',
    'Verbalers': 'Verbalers',
    'Verbaler Overview': 'Verbaler Overview',
    'Verbaler Companies': 'Verbaler Companies',
    'Verbaler Draft': 'Verbaler Draft',
    'Verbalers Report': 'Verbalers Report',
    'Funder Bonus Report': 'Funder Bonus Report',
    'Funder Pipeline': 'Funder Pipeline',
    'Income Request': 'Income Request',
    'Permission Tags': 'Permission Tags',
    'Completed Verbals': 'Completed Verbals'
};

export default en;
